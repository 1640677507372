import styled from "styled-components";

export const BadaraStyled = styled.div`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute !important;
    background: #e15125;

    display: grid;
    justify-content: center;
    align-content: center;

    top: -1px;
    right: 4px;

    &.norm {
        background: #51890a;
    }
`;

export const ImagesStyled = styled.div`
    display: grid;
    grid-template-columns: 109px auto 109px;
    width: 277px;
    margin: 23px auto 25px;

    &.many {
        display: block;
        width: auto;
        margin-top: 49px;
        margin-bottom: 0;

        @media (max-width: 460px) {
            margin-top: 29px;
        }

        p {
            margin: 9px 0 0 0;

            @media (max-width: 370px) {
                font-size: 13px;
            }
        }
    }

    img {
        width: 109px;
        border-radius: 50%;
    }

    .lllizcsr {
        svg {
            margin-top: 48px;
        }
    }

    div {
        position: relative;
    }

    p {
        font-size: 17px;
        font-weight: 400;
        margin: 7px 0 0 0;
        line-height: 1;
    }
`;

export const ErorrTitleStyled = styled.h4`
    color: #f1383d;
    font-size: 19px;
    margin: 20px 0 0 0;
    font-weight: 400;
`;

export const ImageIconStyled = styled.div`
    img {
        width: 74px;
    }
`;
