import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { CommonWrapperStyled } from "../../commonStyles";
import styled from "styled-components";
import Button from "../Button";
import { MainContext } from "../../../ContextState";
import { PhoneNumberUtil } from "google-libphonenumber";
import { defaultCountries, parseCountry } from "react-international-phone";
import "react-international-phone/style.css";
import Modal from "../../../common/Modal";
import HarlemShake from "../HarlemShake";
import Terms from "../Terms";
import { STAGES_STEPS } from "../../../common/dataMaps";
import { sendOtp, verifyOtp } from "../../../ApiCalls";
import Loader from "../Loader";
import { setTokenCookie } from "../../../common/cookie";
import PhoneInputComponent from "./PhoneInput";
import EmailInputComponent from "./EmailInput";
import { trackMixEvent } from "../../../common/eventManager";

const phoneUtil = PhoneNumberUtil.getInstance();

const CheckBoxStyled = styled.div`
    margin: auto;

    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 10px !important;

    input {
        display: inline-block;
        vertical-align: top;
        width: 15px;
        height: 15px;
    }

    label {
        display: inline-block;
        //line-height: 1.2;
        vertical-align: top;
        margin-inline-start: 5px;
        font-size: 15px;
        text-align: start;
    }

    .terms-link {
        cursor: pointer;

        a {
            color: #5d35ff;
        }
    }

    a {
        text-decoration: underline;

        &:focus {
            text-decoration: underline;
        }
    }

    .error-terms {
        color: #ff0000ff;
    }
`;

const ActionsStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    text-align: center;
    width: 340px;
    margin: 10px auto;

    .change-phone {
        color: #5d35ff;
        cursor: pointer;
    }

    .send-again {
        color: #5d35ff;
        cursor: pointer;
    }

    .disabled {
        color: #ccc;
        cursor: not-allowed;
        pointer-events: none;
    }
`;

const FormWrapperStyled = styled.div`
    display: grid;
    width: 241px;
    margin: 13px auto 0;
    position: relative;

    & > div {
        margin-bottom: 17px;
    }

    &.st-lb-en,
    &.st-lb-da {
        label {
            span {
                right: inherit !important;
                left: 18px !important;
            }
        }

        .gender-option-span {
            right: inherit !important;
            left: 18px !important;
        }
    }

    &.otp {
        width: 100%;

        input {
            width: 3rem !important;
            height: 3rem;
            margin: 0 1rem;
            font-size: 2rem;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.3);

            @media (max-width: 568px) {
                height: 2rem;
                margin: 0 0.4rem;
                width: 2rem !important;
            }
        }
    }

    .otp-error {
        color: #ff0000ff;
        margin-top: 10px;
    }
`;

export const AlertRoleStyled = styled.div`
    margin: 0;
    padding: 0;
    font-size: 0;
    position: fixed;
`;

const DELIVERY_TYPES = {
    SMS: "sms",
    EMAIL: "email"
};

function OtpStage({ currentLang, lang, updateProgress, progress, imageBlob, clientId }) {
    const labelRefs = [];

    const { configContext, stageNumberContext } = useContext(MainContext);
    const [config] = configContext;
    const [, s_currentStage] = stageNumberContext;

    const [, setError] = useState("");
    const [otp, setOtp] = useState("");

    const [termsErrorOpened, s_termsErrorOpened] = useState(false);
    const [termsErrorOpened2, s_termsErrorOpened2] = useState(false);
    const [isPhoneError, s_isPhoneError] = useState(false);
    const [isPhoneTouched, s_isPhoneTouched] = useState(false);

    const [emailErrorOpened, s_emailErrorOpened] = useState(false);

    const [otpMode, s_otpMode] = useState(false);
    const [loaderStep, s_loaderStep] = useState("");
    const [verificationError, s_verificationError] = useState(false);

    const [alertContent, s_alertContent] = useState("");
    const [openTermModal, s_openTermModal] = useState(false);
    const [terms, s_terms] = useState(false);
    const [terms2, s_terms2] = useState(false);

    const [timer, setTimer] = useState(null);
    const [sendAgainIsDisabled, s_sendAgainIsDisabled] = useState(false);

    const otpDeliveryType = config.otpDeliveryType;
    // const otpDeliveryType = "email";

    useEffect(() => {
        trackMixEvent("otp_first_open", "", "", "", clientId);
    }, []);

    useEffect(() => {
        let interval;

        if (timer !== null) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        s_sendAgainIsDisabled(false);
                        return null;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [timer]);

    useEffect(() => {
        if (otp.length === 4) {
            s_loaderStep("VERIFICATION");

            const dataToSend = {
                clientId: clientId,
                code: otp
            };

            if (otpDeliveryType === DELIVERY_TYPES.SMS) {
                dataToSend.phone = progress.phone;
            }
            if (otpDeliveryType === DELIVERY_TYPES.EMAIL) {
                dataToSend.email = progress.email;
            }

            verifyOtp(dataToSend)
                .then((res) => {
                    if (res) {
                        s_loaderStep("");
                        setTokenCookie(res.token);
                        s_currentStage(STAGES_STEPS.INFO);
                    } else {
                        setOtp("");
                        trackMixEvent("otp_code_failed", "", "", "", clientId);
                        s_loaderStep("");
                        s_verificationError(true);
                    }
                })
                .catch(() => {
                    trackMixEvent("otp_code_failed", "", "", "", clientId);
                    setOtp("");
                    s_loaderStep("");
                    s_verificationError(true);
                });
        }
    }, [otp]);

    const isPhoneValid = (phone) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (e) {
            return false;
        }
    };

    const openTermsFunc = () => {
        s_openTermModal(!openTermModal);
        document.body.classList.remove("modal-active");
    };

    let isButtonDisabled = false;

    const nextStage = async (event) => {
        if (isButtonDisabled) {
            event.preventDefault();
            setError(lang.error.error_stage);
            if (otpDeliveryType === DELIVERY_TYPES.SMS && !progress.phone) {
                s_isPhoneError(true);
            }
            if (otpDeliveryType === DELIVERY_TYPES.SMS) {
                try {
                    const isValid = phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(progress?.phone));
                    if (isValid) {
                        s_isPhoneError(false);
                    } else {
                        s_isPhoneError(true);
                    }
                } catch (e) {
                    s_isPhoneError(true);
                }
            }
            if (!terms) {
                s_termsErrorOpened(true);
            }
            // if (!terms2) {
            //     s_termsErrorOpened2(true);
            // }
            return false;
        }

        const dataToSend = {
            clientId: clientId,
            phone: progress.phone,
            email: progress.email
        };

        await sendOtp(dataToSend);
        trackMixEvent("otp_send_clicked", "click", "", "", clientId);
        s_otpMode(true);
        setTimer(60);
        s_sendAgainIsDisabled(true);
    };

    if (otpDeliveryType === DELIVERY_TYPES.SMS) {
        if (!progress.phone) {
            isButtonDisabled = true;
        }
        isButtonDisabled = isPhoneValid(progress.phone) === false;
    }

    if (otpDeliveryType === DELIVERY_TYPES.EMAIL) {
        if (!progress.email) {
            isButtonDisabled = true;
        }
        if (emailErrorOpened) {
            isButtonDisabled = true;
        }
    }

    if (!terms) {
        isButtonDisabled = true;
    }
    // if (!terms2) {
    //     isButtonDisabled = true;
    // }

    const countries = defaultCountries.filter((country) => {
        const { iso2 } = parseCountry(country);
        // return ['il', 'us'].includes(iso2);
        return [config.countryCode].includes(iso2);
    });

    const classes = `st-lb-${currentLang}`;

    labelRefs[0] = React.createRef();
    labelRefs[1] = React.createRef();

    let title = lang.otp?.title;

    if (otpDeliveryType === DELIVERY_TYPES.EMAIL) {
        title = lang.otp?.title_email;
    }
    if (otpDeliveryType === DELIVERY_TYPES.SMS) {
        title = lang.otp?.title;
    }

    if (otpMode) {
        title = lang.otp?.title_2;

        if (otpDeliveryType === DELIVERY_TYPES.EMAIL) {
            title = lang.otp?.title_2_email;
        }
    }

    const termsUrl = config?.termsUrl;

    return (
        <CommonWrapperStyled className="stage2">
            {alertContent && <AlertRoleStyled role="alert">{alertContent}</AlertRoleStyled>}
            <div className="imageUploaded selectedImage stage2-imageUploaded">
                <img
                    onClick={() => {
                        s_currentStage(STAGES_STEPS.MEDIA);
                    }}
                    src={imageBlob}
                    alt="selected"
                />
            </div>
            <div className="information">
                <div className="sub-text">{title}</div>
            </div>
            <div className="horizontal-line-stage2" />

            {!otpMode && (
                <FormWrapperStyled className={classes}>
                    {otpDeliveryType === DELIVERY_TYPES.SMS && (
                        <PhoneInputComponent
                            isPhoneError={isPhoneError}
                            progress={progress}
                            s_isPhoneTouched={s_isPhoneTouched}
                            config={config}
                            countries={countries}
                            updateProgress={updateProgress}
                            isPhoneTouched={isPhoneTouched}
                            s_isPhoneError={s_isPhoneError}
                        />
                    )}
                    {otpDeliveryType === DELIVERY_TYPES.EMAIL && (
                        <EmailInputComponent
                            emailErrorOpened={emailErrorOpened}
                            langData={lang}
                            s_alertContent={s_alertContent}
                            progress={progress}
                            updateProgress={updateProgress}
                            s_emailErrorOpened={s_emailErrorOpened}
                        />
                    )}

                    <CheckBoxStyled>
                        <input
                            aria-checked={terms}
                            aria-labelledby="scales"
                            onChange={() => {
                                s_termsErrorOpened(false);
                                s_terms(!terms);
                            }}
                            checked={terms}
                            id="scales"
                            type="checkbox"
                        />
                        <label htmlFor="scales" className={termsErrorOpened ? "error-terms" : ""}>
                            {lang.stage2.terms}
                            <span className="terms-link">
                                <a rel="noreferrer" target="_blank" href={termsUrl}>
                                    {lang.stage2.terms_part_2}
                                </a>
                            </span>
                        </label>
                    </CheckBoxStyled>
                    {/*<CheckBoxStyled>*/}
                    {/*    <input*/}
                    {/*        aria-checked={ terms2 }*/}
                    {/*        aria-labelledby="scales"*/}
                    {/*        onChange={ () => {*/}
                    {/*            s_termsErrorOpened2(false);*/}
                    {/*            s_terms2(!terms2);*/}
                    {/*        } }*/}
                    {/*        checked={ terms2 } id="scales2" type="checkbox"*/}
                    {/*    />*/}
                    {/*    <label htmlFor="scales2" className={ termsErrorOpened2 ? "error-terms" : "" }>*/}
                    {/*        { lang.stage2.terms_sms }*/}
                    {/*    </label>*/}
                    {/*</CheckBoxStyled>*/}
                    <div style={{ width: "100%" }} className="horizontal-line-stage2" />
                </FormWrapperStyled>
            )}
            {otpMode && (
                <FormWrapperStyled className={`${classes} otp`}>
                    <OtpInput
                        shouldAutoFocus={true}
                        containerStyle={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            margin: "0 auto",
                            direction: "ltr"
                        }}
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        inputType="number"
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                    />

                    {loaderStep === "" && (
                        <ActionsStyled>
                            <div
                                onClick={() => {
                                    s_otpMode(false);
                                    setOtp("");
                                }}
                                className="change-phone"
                            >
                                {lang.otp?.change_phone_number}
                            </div>
                            <div
                                onClick={() => {
                                    const dataToSend = {
                                        clientId: clientId,
                                        phone: progress.phone,
                                        email: progress.email
                                    };

                                    sendOtp(dataToSend);
                                    setTimer(60);
                                    s_sendAgainIsDisabled(true);
                                }}
                                className={`send-again ${sendAgainIsDisabled ? "disabled" : ""}`}
                            >
                                {lang.otp?.send_again} {sendAgainIsDisabled ? `(${timer})` : ""}
                            </div>
                        </ActionsStyled>
                    )}
                    {loaderStep === "VERIFICATION" && (
                        <Loader ariaLabel={lang.general.loading} height={50} width={50} />
                    )}

                    {verificationError && <div className="otp-error">{lang.otp?.code_is_invalid}</div>}
                </FormWrapperStyled>
            )}

            {!otpMode && (
                <HarlemShake noShake={isButtonDisabled}>
                    <Button onClick={nextStage} className={isButtonDisabled ? "next-button disabled" : "next-button"}>
                        {lang.general.next}
                    </Button>
                </HarlemShake>
            )}

            {openTermModal && (
                <Modal ariaTitle={""} isOpen={true} onClose={openTermsFunc} lang={lang}>
                    <Terms lang={currentLang} langData={lang} />
                </Modal>
            )}
        </CommonWrapperStyled>
    );
}

export default OtpStage;
