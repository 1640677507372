import React, { useContext, useEffect, useRef, useState } from "react";
import { CommonWrapperStyled } from "../commonStyles";
import styled from "styled-components";
import CustomInput from "./CustomInput";
import Button from "./Button";
import { Tooltip } from "react-tooltip";
import { MainContext } from "../../ContextState";
import "react-international-phone/style.css";
import Modal from "../../common/Modal";
import HarlemShake from "./HarlemShake";
import RecentPrebooks from "./RecentPrebooks";
import Terms from "./Terms";
import { STAGES_STEPS } from "../../common/dataMaps";
import { trackMixEvent } from "../../common/eventManager";

const OptionsStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    width: 243px;
    margin: 0 auto 0;
    grid-gap: 10px;
    position: relative;
`;

const GenderLabelStyled = styled.span`
    display: inline-block;
    position: absolute;
    transition: all 0.1s ease-in-out;
    font-size: 14px;
    background-color: white;
    padding: 0 4px;
    color: black;
    margin: 0;
    right: 18px;
    top: -8px;
    line-height: 1;
`;

const FormWrapperStyled = styled.div`
    display: grid;
    width: 241px;
    margin: 13px auto 0;
    position: relative;

    & > div {
        margin-bottom: 17px;
    }

    &.st-lb-en,
    &.st-lb-da {
        label {
            span {
                right: inherit !important;
                left: 18px !important;
            }
        }

        .gender-option-span {
            right: inherit !important;
            left: 18px !important;
        }
    }
`;

const AgeInput = styled.div`
    input {
        height: 49px;
        text-align: center;
    }
`;

export const AlertRoleStyled = styled.div`
    margin: 0;
    padding: 0;
    font-size: 0;
    position: fixed;
`;

export const NameValidationStyled = styled.div`
    .pszsde {
        span {
            font-size: 23px;
            margin: 3px;
        }
    }

    .piusss-name {
        background: #ffa927;
    }
`;

export const NameValidationButtonsWrapperStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 75px;
`;

function Stage2({
    currentLang,
    lang,
    updateProgress,
    progress,
    initBook,
    recentPrebooks,
    removePrebook,
    imageBlob,
    clientId
}) {
    const genderRef = useRef(null);
    const ageRef = useRef(null);
    const nameRef = useRef(null);
    const labelRefs = [];

    const focusLabel = (index) => {
        labelRefs[index].current.focus();
    };

    const { configContext, stageNumberContext } = useContext(MainContext);
    const [config] = configContext;
    const [, s_currentStage] = stageNumberContext;

    const [, setError] = useState("");

    const [ageErrorOpened, s_ageErrorOpened] = useState(false);
    const [emailErrorOpened, s_emailErrorOpened] = useState(false);
    const [isNameError, s_isNameError] = useState(false);
    const [isGenderError, s_isGenderError] = useState(false);

    const [onFocusGender, s_onFocusGender] = useState(0);
    const [alertContent, s_alertContent] = useState("");
    const [nameModal, s_nameModal] = useState(false);
    const [openTermModal, s_openTermModal] = useState(false);

    const maxAge = config?.maxAge || 60;
    const minAge = config?.minAge || 1;

    const age_validation = `${lang.stage2.fields.age_validation} ${minAge} - ${maxAge}`;

    const fieldVal = (fieldName, event) => {
        updateProgress(fieldName, event.target.value);
    };

    useEffect(() => {
        trackMixEvent("hero_details_first_open", "", "", "", clientId);
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (onFocusGender) {
                switch (event.key) {
                    case " ": // space
                    case "Enter": // space
                        // updateProgress(key, onFocusValue.value);
                        break;
                    case "ArrowLeft":
                    case "ArrowDown":
                        if (onFocusGender === 1) {
                            updateProgress("gender", "female");
                            s_onFocusGender(2);
                            focusLabel(1);
                        }
                        break;
                    case "ArrowRight":
                    case "ArrowUp":
                        if (onFocusGender === 2) {
                            s_onFocusGender(1);
                            focusLabel(0);
                            updateProgress("gender", "male");
                        }
                        break;
                    default:
                        break;
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [onFocusGender]);

    useEffect(() => {
        let timer;

        timer = setTimeout(() => {
            if (progress.age) {
                handleErrorAge();
            }
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [progress.age]);

    const handleErrorAge = () => {
        let isError = false;
        if (progress.age) {
            const valueNumber = parseInt(progress.age, 10);
            if (valueNumber < minAge || valueNumber > maxAge) {
                isError = true;
            }
        }
        if (progress.age === "") {
            isError = true;
        }

        if (isError) {
            s_alertContent(age_validation);
            s_ageErrorOpened(true);
        } else {
            s_alertContent("");
            s_ageErrorOpened(false);
        }
    };

    const openSelectionFunc = () => {
        s_nameModal(!nameModal);
        document.body.classList.remove("modal-active");
    };

    const openTermsFunc = () => {
        s_openTermModal(!openTermModal);
        document.body.classList.remove("modal-active");
    };

    let isButtonDisabled = progress.age < minAge || progress.age > maxAge || !progress.gender || !progress.name;

    const nextStage = (event) => {
        if (isButtonDisabled) {
            event.preventDefault();
            setError(lang.error.error_stage);
            if (!progress.age) {
                s_ageErrorOpened(true);
            }
            if (!progress.name) {
                s_isNameError(true);
            }
            if (!progress.gender) {
                s_isGenderError(true);
            }
            return false;
        }

        const words = progress.name.split(" ");
        if (words.length === 1) {
            goToNext();
        } else {
            s_nameModal(true);
        }
    };

    const goToNext = () => {
        trackMixEvent("hero_details_continue_clicked", "click", "", "", clientId);
        initBook();
    };

    if (!isButtonDisabled) {
        isButtonDisabled = emailErrorOpened;
    }
    if (ageErrorOpened) {
        isButtonDisabled = true;
    }

    const classes = `st-lb-${currentLang}`;

    labelRefs[0] = React.createRef();
    labelRefs[1] = React.createRef();

    const showRecentPrebooks = recentPrebooks?.length > 0;

    return (
        <CommonWrapperStyled className="stage2">
            {alertContent && <AlertRoleStyled role="alert">{alertContent}</AlertRoleStyled>}
            <div className="imageUploaded selectedImage stage2-imageUploaded">
                <img
                    onClick={() => {
                        s_currentStage(STAGES_STEPS.MEDIA);
                    }}
                    src={imageBlob}
                    alt="selected"
                />
            </div>
            <div className="information">
                <div className="sub-text">{lang.stage2.continue_text}</div>
            </div>
            <div className="horizontal-line-stage2" />

            <FormWrapperStyled className={classes}>
                <CustomInput
                    reff={nameRef}
                    label={progress.gender === "female" ? lang.stage2.fields.name_female : lang.stage2.fields.name_male}
                    value={progress.name}
                    onChange={(event) => {
                        s_isNameError(false);
                        fieldVal("name", event);
                    }}
                    name="name"
                    ariaRequired={true}
                    onFocus={(event) => {
                        event.target.select();
                    }}
                    isInvalid={isNameError}
                />

                <OptionsStyled className="middleOptions">
                    <GenderLabelStyled className="gender-option-span">{lang.stage2.fields.sex}</GenderLabelStyled>
                    <div
                        className={isGenderError ? "gender error-gender-lo" : "gender"}
                        role="radiogroup"
                        aria-label={"בחר מין"}
                        ref={genderRef}
                    >
                        <input
                            type="radio"
                            name="gender"
                            id="male"
                            value="male"
                            checked={progress.gender === "male"}
                            onChange={(event) => {
                                fieldVal("gender", event);
                                s_isGenderError(false);
                            }}
                        />
                        <label
                            ref={labelRefs[0]}
                            role="radio"
                            aria-checked={progress.gender === "male"}
                            onFocus={() => {
                                s_onFocusGender(1);
                            }}
                            onBlur={() => {
                                s_onFocusGender(0);
                            }}
                            aria-label={lang.stage2.fields.gender.male}
                            tabIndex={progress.gender === "male" ? "0" : "-1"}
                            htmlFor="male"
                        >
                            {lang.stage2.fields.gender.male}
                        </label>
                        <input
                            type="radio"
                            name="gender"
                            id="female"
                            value="female"
                            checked={progress.gender === "female"}
                            onChange={(event) => {
                                fieldVal("gender", event);
                                s_isGenderError(false);
                            }}
                        />
                        <label
                            ref={labelRefs[1]}
                            aria-checked={progress.gender === "female"}
                            role="radio"
                            onFocus={() => {
                                s_onFocusGender(2);
                            }}
                            onBlur={() => {
                                s_onFocusGender(0);
                            }}
                            tabIndex={progress.gender === "female" ? "0" : "-1"}
                            aria-label={lang.stage2.fields.gender.female}
                            htmlFor="female"
                        >
                            {lang.stage2.fields.gender.female}
                        </label>
                    </div>
                    <Tooltip id="warning-tooltip" className="pizeda" isOpen={ageErrorOpened} />
                    <AgeInput
                        data-tooltip-id="warning-tooltip"
                        data-tooltip-content={age_validation}
                        data-tooltip-variant="error"
                    >
                        <CustomInput
                            reff={ageRef}
                            ariaDescribedby={"warning-tooltip"}
                            isInvalid={ageErrorOpened}
                            type="number"
                            inputmode="numeric"
                            label={lang.stage2.fields.age_hero}
                            value={progress.age}
                            onChange={(event) => {
                                const val = event.target.value;
                                updateProgress("age", val);
                                if (val) {
                                    const valueNumber = parseInt(val, 10);
                                    if (valueNumber > minAge && valueNumber < maxAge) {
                                        s_ageErrorOpened(false);
                                    }
                                }
                            }}
                            onFocus={(event) => {
                                event.target.select();
                            }}
                            onBlur={() => {
                                handleErrorAge();
                            }}
                        ></CustomInput>
                    </AgeInput>
                </OptionsStyled>

                <div style={{ width: "100%" }} className="horizontal-line-stage2" />

                {showRecentPrebooks && (
                    <RecentPrebooks
                        lang={currentLang}
                        langData={lang}
                        items={recentPrebooks}
                        removePrebook={removePrebook}
                    />
                )}
            </FormWrapperStyled>
            <HarlemShake noShake={isButtonDisabled}>
                <Button onClick={nextStage} className={isButtonDisabled ? "next-button disabled" : "next-button"}>
                    {lang.general.next}
                </Button>
            </HarlemShake>

            {nameModal && (
                <Modal
                    role="alertdialog"
                    isOpen={true}
                    onClose={openSelectionFunc}
                    lang={currentLang}
                    backGroundClose={true}
                    ariaTitle={lang.arias.name_validation}
                >
                    <div className="popup_container" style={{ height: 190 }}>
                        <NameValidationStyled>
                            <div className="pszsde">
                                <span>{lang.stage2.name_validation_1}</span>
                                <span className="piusss-name">{progress.name}</span>
                                <span>
                                    {progress.gender === "male"
                                        ? lang.stage2.name_validation_2_male
                                        : lang.stage2.name_validation_2_female}
                                </span>
                            </div>
                            <NameValidationButtonsWrapperStyled>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openSelectionFunc();
                                        setTimeout(() => {
                                            goToNext();
                                        }, 500);
                                    }}
                                    className="next-button"
                                    disabled={isButtonDisabled}
                                >
                                    {lang.stage2.yes}
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openSelectionFunc();
                                    }}
                                    className="next-button"
                                    disabled={isButtonDisabled}
                                >
                                    {lang.stage2.no}
                                </Button>
                            </NameValidationButtonsWrapperStyled>
                        </NameValidationStyled>
                    </div>
                </Modal>
            )}
            {openTermModal && (
                <Modal ariaTitle={""} isOpen={true} onClose={openTermsFunc} lang={lang}>
                    <Terms lang={currentLang} langData={lang} />
                </Modal>
            )}
        </CommonWrapperStyled>
    );
}

export default Stage2;
