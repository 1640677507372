import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getClientIdCookie, setAffRefCookie } from "./common/cookie";
import { getConfig } from "./ApiCalls";
import { MainContext } from "./ContextState";
import styled from "styled-components";
import NotActive from "./stages/components/NotActive";
import Loader from "./stages/components/Loader";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";

const WraperStyled = styled.div`
    margin: auto;
    text-align: center;

    .loader-o {
        margin-top: 20px;
    }
`;

if (process.env.NODE_ENV === "production") {
    LogRocket.init("uamlxw/frame-prod", {
        rootHostname: "mebook.ai",
    });

    Sentry.init({
        dsn: "https://50b3aadf166e5fb1ea01fa2aaaad8931@o4506189547044864.ingest.sentry.io/4506189554188288",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", "mebook"]
            }),
            new Sentry.Replay()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    mixpanel.init("8b3a99edcc90e93fdf660d440d64eeaf", {
        debug: false,
        track_pageview: true,
        persistence: "localStorage",
        cookie_domain: ".mebook.ai"
    });
}

const Wrapper = () => {
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const lang = queryParameters.get("language") || "he";
    const clientIdQueryParams = queryParameters.get("clientId") || "64d491633684ef0d32edb473";
    const affRef = queryParameters.get("ref") || "";
    const cookieClientId = getClientIdCookie();

    const [loadingConfig, s_loadingConfig] = useState(true);
    const [isActive, s_isActive] = useState(false);

    const { configContext, clientIdContext } = useContext(MainContext);
    const [, s_config] = configContext;
    const [, s_clientId] = clientIdContext;

    useEffect(() => {
        if (affRef) {
            setAffRefCookie(affRef);
        }
    }, [affRef]);

    useEffect(() => {
        let GTM_ID = "G-XG8MR1JK7R";
        if (clientIdQueryParams === "67065c9a3592c638ec3470a4") {
            GTM_ID = "GTM-MN2LLDHS";
        }
        const gtmScript = document.createElement("script");
        gtmScript.async = true;
        gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${ GTM_ID }`;
        document.head.appendChild(gtmScript);

        // Initialize GTM gtag
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag("js", new Date());
        gtag("config", GTM_ID);

        // israel pixel
        let FB_PIXEL_ID = "1097268434915469";
        if (clientIdQueryParams === "66bdbdba3e314dbad68940b8") {
            FB_PIXEL_ID = "563364439447860";
        }

        const fbScript = document.createElement("script");
        fbScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${ FB_PIXEL_ID }');
      fbq('track', 'PageView');
    `;
        document.head.appendChild(fbScript);

        return () => {
            // Cleanup scripts when component unmounts
            document.head.removeChild(gtmScript);
            document.head.removeChild(fbScript);
        };
    }, []);

    useEffect(() => {
        const relevantClientId = cookieClientId || clientIdQueryParams;

        s_clientId(relevantClientId);
        getConfig(lang, relevantClientId)
            .then((data) => {
                s_config(data);
                s_loadingConfig(false);
                if (data.isActive) {
                    s_isActive(true);
                }
            })
            .catch((e) => {
                console.log("e", e);
            });
    }, []);

    useEffect(() => {
        const disableBFCache = () => {
        };

        window.addEventListener("beforeunload", disableBFCache);

        return () => {
            window.removeEventListener("beforeunload", disableBFCache);
        };
    }, []);

    const loading = loadingConfig;

    return (
        <div>
            { loading && (
                <WraperStyled>
                    <Loader />
                </WraperStyled>
            ) }
            { !loading && isActive && <Outlet /> }

            { !loading && !isActive && <NotActive /> }
        </div>
    );
};

export default Wrapper;
